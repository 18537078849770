import styled from "styled-components";
import { mediaMax } from "../../../helpers/MediaQueries";

export const CenterWrapper = styled.div`
  display: flex;
  margin: 0 10% 72px;
  ${mediaMax.tabletLandscape`
    margin: 0 6.25% 48px;
    flex-direction: column-reverse;
  `}
  ${mediaMax.phoneXL`
    flex-direction: column; 
  `};
`;
export const CenterLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 37%;
  ${mediaMax.tabletLandscape`
    width: 74.4%;
    padding-top: 48px;
    margin-left: 13%;
  `}
  ${mediaMax.phoneXL`
    padding: 0 0 48px 0;
    width: 100%;
    margin-left: 0;    
  `};
`;
export const CenterRight = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  width: 48%;
  ${mediaMax.tabletLandscape`
    width: 49%;
    margin-left: 13%;
  `}
  ${mediaMax.phoneXL`
    width: 57%;
    margin: 0 auto;
  `}
`;

export const FullscreenImageLabel = styled("p")`
  margin: 12px 0 0;
  width: 100%;
`;
