import styled from "styled-components";
import { mediaMax } from "../../../helpers/MediaQueries";

export const ContentType1Wrapper = styled("div")`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin: 0 10% 72px;
  ${mediaMax.tabletLandscape`
    margin: 0 6.25% 48px;
  `};
  ${mediaMax.phoneXL`
      flex-direction: column-reverse;
  `};
`;
export const ContentTypeCol1 = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 49%;
  .gatsby-image-wrapper {
    width: 100%;
  }
  .image-label {
    width: 100%;
    margin: 12px 0 0;
  }
  ${mediaMax.tabletLandscape`
    width: 31.6%;
    justify-content: center;
  `};
  ${mediaMax.phoneXL`
     width: 57%;
     margin: 48px auto 0;
  `};
`;
export const ContentTypeCol2 = styled("div")`
  display: flex;
  flex-direction: column-reverse;
  justify-content: normal;
  width: 38%;
  .gatsby-image-wrapper {
    display: block;
    margin: 48px 0 0 0;
  }
  ${mediaMax.tabletLandscape`
    width: 61.7%;
  `};
  ${mediaMax.phoneXL`
     width: 100%;
  `};
`;
export const ContentType1RightContainer = styled.div`
  ${mediaMax.phoneXL`
    width: 100%;
  `};
`;
