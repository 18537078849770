import styled from "styled-components";
import { mediaMax } from "../../../helpers/MediaQueries";

export const ContentImageTextWrapper = styled("div")<{
  reversed: boolean | undefined;
}>`
  display: flex;
  flex-direction: ${(props: { reversed: boolean | undefined }) =>
    props.reversed ? "row-reverse" : "row"};
  justify-content: space-between;
  margin: 0 10% 72px;
  ${mediaMax.tabletLandscape`
    margin: 0 6.25% 48px;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  `};
  ${mediaMax.phoneXL`
    flex-direction: column;
  `};
`;
export const ContentImageTextCol2 = styled("div")<{
  reversed: boolean | undefined;
}>`
  display: flex;
  flex-direction: column;
  justify-content: ${(props: { reversed: boolean | undefined }) =>
    props.reversed ? "center" : "normal"};
  width: 39%;
  ${mediaMax.tabletLandscape`
    padding-left: 25.5%;
    max-width: 680px;
    width: 100%;
  `};
  ${mediaMax.phoneXL`
    padding-left: 0;
  `}
`;
export const ContentImageTextCol1 = styled("div")<{
  imgMulti2: string | undefined;
}>`
  display: flex;
  align-items: flex-start;
  justify-content: ${(props: { imgMulti2: string | undefined }) =>
    props.imgMulti2 ? "space-between" : "flex-end"};
  width: ${(props: { imgMulti2: string | undefined }) =>
    props.imgMulti2 ? "48.5%" : "49%"};
  .gatsby-image-wrapper {
    width: ${(props: { imgMulti2: string | undefined }) =>
      props.imgMulti2 ? "42.5%" : "47%"};
  }
  ${mediaMax.tabletLandscape`
    width: ${(props: { imgMulti2: string | undefined }) =>
      props.imgMulti2 ? "100%" : "49%"};
    margin: ${(props: { imgMulti2: string | undefined }) =>
      props.imgMulti2 ? "0" : "48px 0 0 25.5%"};
    .gatsby-image-wrapper {
      width: ${(props: { imgMulti2: string | undefined }) =>
        props.imgMulti2 ? "46.5%" : "100%"};
    }
  `};
  ${mediaMax.phoneXL`
    width: ${(props: { imgMulti2: string | undefined }) =>
      props.imgMulti2 ? "100%" : "47%"};
  `};
`;
export const ContentImageTextRightContainer = styled.div`
  ${mediaMax.phoneXL`
    padding-top: 48px;
  `};
`;
