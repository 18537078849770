import styled from "styled-components";
import { mediaMax } from "../../../helpers/MediaQueries";

export const WaterGrillWrapper = styled.div`
  margin: 0 10% 72px;
  ${mediaMax.tabletLandscape`
    margin: 0 6.25% 48px;
  `};
  .image-label {
    width: 100%;
    margin: 12px 0 0 54%;
  }
`;

export const WaterGrillImagesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const WaterGrillCol1 = styled.div`
  display: block;
  justify-content: center;
  width: 48.5%;
  ${mediaMax.tabletLandscape`
    width: 46.5%;
  `};
  img {
    width: 100%;
    height: 91%;
    object-fit: cover;
  }
`;

export const WaterGrillCol2 = styled.div`
  display: block;
  width: 38.2%;
  margin-top: -20%;
  ${mediaMax.tabletLandscape`
    margin-top: 0;
    width: 46.5%;
  `};
  img {
    width: 100%;
    height: 91%;
    object-fit: cover;
  }
  p {
    margin-top: 5px;
    font: normal normal 100 12px/14px Optima;
    letter-spacing: 1.8px;
    color: #535d53;
    text-transform: uppercase;
  }
`;
