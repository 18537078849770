import React, { FC, useEffect, useRef } from "react";
import { CenterLeft, CenterRight, CenterWrapper } from "./index.styled";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
import { FullscreenImageLabel } from "./index.styled";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const Center: FC = () => {
  const centerWrapperRef = useRef<HTMLDivElement>(null);
  const centerRightRef = useRef<HTMLDivElement>(null);
  const centerHeaderRef = useRef<HTMLHeadingElement>(null);
  const centerTextImgRef = useRef<HTMLParagraphElement>(null);
  useEffect(() => {
    ScrollTrigger.create({
      trigger: centerWrapperRef?.current || "",
      toggleActions: "play none none reverse",
      start: "top+=30% bottom",
      animation: gsap
        .timeline()
        .fromTo(
          centerRightRef?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          "+=.1"
        )
        .fromTo(
          centerHeaderRef?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          "-=.2"
        )
        .fromTo(
          centerTextImgRef?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          "-=.2"
        ),
    });
  }, []);

  const data = useStaticQuery(graphql`
    query {
      neighborhood6: file(relativePath: { eq: "neighborhood-6.png" }) {
        childImageSharp {
          fluid(maxWidth: 2560) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  return (
    <CenterWrapper ref={centerWrapperRef}>
      <CenterLeft>
        <h2 className={"body-title"} ref={centerHeaderRef}>
          THE CENTER OF IT ALL
        </h2>
        <p className={"body-copy"} ref={centerTextImgRef}>
          A central location gives Uptown Newport true cache – everything you
          desire or require is never more than a few miles away. Arrive to South
          Coast Plaza or Fashion Island in minutes, or easily lock and leave
          your home and head to the gates or private terminal of John Wayne
          Airport, just up MacArthur Boulevard. Parkhouse&nbsp;Residences at
          Uptown Newport is connected to greater Newport, yet offers a
          personality and sense of community all its own.
        </p>
      </CenterLeft>
      <CenterRight ref={centerRightRef}>
        <Img
          fluid={data.neighborhood6.childImageSharp.fluid}
          alt={"Parkhouse center"}
          loading={"eager"}
        />
        <FullscreenImageLabel className={"image-label"}>
          NOGUCHI SCULPTURE GARDEN
        </FullscreenImageLabel>
      </CenterRight>
    </CenterWrapper>
  );
};

export default Center;
