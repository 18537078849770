import React, { FC, useEffect, useRef } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { IWaterGrillProps } from "../../../interfaces/water-grill-props";
import {
  WaterGrillWrapper,
  WaterGrillImagesWrapper,
  WaterGrillCol1,
  WaterGrillCol2,
} from "./index.styled";

const WaterGrill: FC<IWaterGrillProps> = ({ ...props }) => {
  const waterGrillWrapperRef = useRef<HTMLDivElement>(null);
  const waterGrillCol1Ref = useRef<HTMLDivElement>(null);
  const waterGrillCol2Ref = useRef<HTMLDivElement>(null);
  const waterGrillLabelRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: waterGrillWrapperRef?.current || "",
      toggleActions: "play none none reverse",
      start: "top+=20% bottom",
      animation: gsap
        .timeline()
        .fromTo(
          waterGrillCol1Ref?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          "+=.1"
        )
        .fromTo(
          waterGrillCol2Ref?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          "+=.1"
        )
        .fromTo(
          waterGrillLabelRef?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          "+=.1"
        ),
    });
  });

  const data = useStaticQuery(graphql`
    query {
      allImageSharp {
        edges {
          node {
            fluid(maxWidth: 2560) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  `);

  return (
    <WaterGrillWrapper ref={waterGrillWrapperRef}>
      <WaterGrillImagesWrapper>
        <WaterGrillCol1 ref={waterGrillCol1Ref}>
          <Img
            fluid={
              data.allImageSharp.edges.find((element: any) => {
                return (
                  element.node.fluid.src.split("/").pop() === props.imgLeft
                );
              }).node.fluid
            }
            alt={"Parkhouse image"}
            loading={"eager"}
          />
        </WaterGrillCol1>
        <WaterGrillCol2 ref={waterGrillCol2Ref}>
          <Img
            fluid={
              data.allImageSharp.edges.find((element: any) => {
                return (
                  element.node.fluid.src.split("/").pop() === props.imgRight
                );
              }).node.fluid
            }
            alt={"Parkhouse image"}
            loading={"eager"}
          />
          <p ref={waterGrillLabelRef}>{props.label}</p>
        </WaterGrillCol2>
      </WaterGrillImagesWrapper>
    </WaterGrillWrapper>
  );
};

export default WaterGrill;
