import styled from "styled-components";
import { mediaMax } from "../../../helpers/MediaQueries";

export const MapWrapper = styled.div`
  display: flex;
  width: 100%;
  ${mediaMax.phoneXL`
    flex-wrap: wrap;
  `};
`;

export const MapHeading = styled.div`
  font-size: 24px;
  letter-spacing: 3.6px;
  color: #535d53;
  padding-bottom: 24px;
  ${mediaMax.tabletLandscape`
    width: 100vw;
    padding-bottom: 34px;
  `};
  ${mediaMax.phoneXL`
    width: 100%;
  `};
`;

export const MapNav = styled.div`
  width: 35%;
  padding: 0 2% 0 5.3%;
  ${mediaMax.phoneXL`
    width:100%
  `};
`;

export const MapItemsCategories = styled.div`
  ul {
    padding-bottom: 24px;
    li {
      list-style: none;
      padding: 0 8px;
      margin-bottom: 8px;
      color: #9aa49a;
      cursor: pointer;
      transition: 0.4s ease-out;
      border-left: 2px solid transparent;
      &:hover {
        color: #535d53;
      }
      &.active {
        color: #535d53;
        border-left: 2px solid #535d53;
      }
    }
  }
`;
export const NavItemsList = styled.ul`
  font-size: 12px;
  letter-spacing: 0.72px;
  text-transform: uppercase;
  p {
    font-weight: 700;
    padding: 12px 0 4px;
  }
  li {
    list-style: none;
    position: relative;
    padding: 2px 10px 2px 20px;
    margin-bottom: 0;
    color: #535d53;
    cursor: pointer;
    transition: 0.25s ease-out;
    ${mediaMax.tabletLandscape`
      text-transform: unset;
      padding: 1px 14px 2px;
    `};
    ${mediaMax.phoneXL`
      text-transform: uppercase;
    `};
    &:before {
      content: "";
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: #535d53;
      position: absolute;
      left: 3px;
      top: 35%;
      transform: translate(-35%);
    }
    &:hover {
      color: #9aa49a;
    }
  }
  ${mediaMax.phoneXL`
    padding: 0 2% 0 5.3%;
  `};
`;

export const MapContainer = styled.div`
  width: 65%;
  height: 89vh;
  ${mediaMax.tabletLandscape`
    height: 58.3vh;
    margin-top: 60px;
  `};
  ${mediaMax.phoneXL`
    width: 100%;
    height: 42.5vh;
    margin-top: 0;
    margin-bottom: 24px;
  `};
  .mapboxgl-popup-content {
    background: #9aa49a;
    color: white;
    padding: 20px 10px 10px;
    line-height: 1.1em;
    max-width: 240px;
    text-align: center;
    border-radius: 0;
  }
  .mapboxgl-popup-tip {
    border-top-color: #9aa49a;
    margin-left: 10px;
    opacity: 0.6;
  }
  .mapboxgl-popup-close-button {
    color: white;
    font-family: sans-serif;
  }
  .mapboxgl-ctrl-bottom-left,
  .mapboxgl-ctrl-bottom-right {
    display: none;
  }
  .mapboxgl-ctrl-group {
    background-color: #9aa49a;
  }
  .mapboxgl-ctrl-zoom-in {
    span {
      background-image: url(data:image/svg+xml;charset=utf8,<svg%20viewBox%3D%270%200%2020%2020%27%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27>%0A%20%20<path%20style%3D%27fill%3A%23fff%3B%27%20d%3D%27M%2010%206%20C%209.446%206%209%206.4459904%209%207%20L%209%209%20L%207%209%20C%206.446%209%206%209.446%206%2010%20C%206%2010.554%206.446%2011%207%2011%20L%209%2011%20L%209%2013%20C%209%2013.55401%209.446%2014%2010%2014%20C%2010.554%2014%2011%2013.55401%2011%2013%20L%2011%2011%20L%2013%2011%20C%2013.554%2011%2014%2010.554%2014%2010%20C%2014%209.446%2013.554%209%2013%209%20L%2011%209%20L%2011%207%20C%2011%206.4459904%2010.554%206%2010%206%20z%27%20%2F>%0A<%2Fsvg>%0A) !important;
    }
  }
  .mapboxgl-ctrl-zoom-out {
    span {
      background-image: url(data:image/svg+xml;charset=utf8,<svg%20viewBox%3D%270%200%2020%2020%27%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27>%0A%20%20<path%20style%3D%27fill%3A%23fff%3B%27%20d%3D%27m%207%2C9%20c%20-0.554%2C0%20-1%2C0.446%20-1%2C1%200%2C0.554%200.446%2C1%201%2C1%20l%206%2C0%20c%200.554%2C0%201%2C-0.446%201%2C-1%200%2C-0.554%20-0.446%2C-1%20-1%2C-1%20z%27%20%2F>%0A<%2Fsvg>%0A) !important;
    }
  }
`;
export const PHMarker = styled.img`
  margin-left: -47%;
  position: absolute;
  transform: scale(12);
`;
export const MapMarker = styled.div`
  width: 12px;
  height: 12px;
  //border-radius: 50%;
  cursor: pointer;
  top: 0;
  left: 0;
  span {
    display: inline-block;
    background-color: #242021;
    width: 8px;
    height: 8px;
    border: 1px solid #fff;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
export const PopupMapItemName = styled.h3`
  font: 14px/14px Helvetica Neue, Arial, Helvetica, sans-serif;
  font-weight: 700;
  padding-bottom: 14px;
  letter-spacing: normal;
`;

export const PopupMapItemAddress = styled.p`
  font: 12px/13px Helvetica Neue, Arial, Helvetica, sans-serif;
  letter-spacing: normal;
  p {
    padding-bottom: 1px;
  }
`;
