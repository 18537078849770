import React, { FC, useEffect, useRef } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { INatureProps } from "../../../interfaces/nature-props";
import { NatureWrapper, NatureCol1, NatureCol2 } from "./index.styled";

const Nature: FC<INatureProps> = ({ ...props }) => {
  const natureWrapperRef = useRef<HTMLDivElement>(null);
  const natureCol1Ref = useRef<HTMLDivElement>(null);
  const natureCol2Ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: natureWrapperRef?.current || "",
      toggleActions: "play none none reverse",
      start: "top+=20% bottom",
      animation: gsap
        .timeline()
        .fromTo(
          natureCol1Ref?.current || null,
          0.5,
          { opacity: 0, x: -30 },
          { opacity: 1, x: 0 },
          "+=.1"
        )
        .fromTo(
          natureCol2Ref?.current || null,
          0.5,
          { opacity: 0, x: 30 },
          { opacity: 1, x: 0 },
          "+=.1"
        ),
    });
  });

  const data = useStaticQuery(graphql`
    query {
      allImageSharp {
        edges {
          node {
            fluid(maxWidth: 2560) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  `);

  return (
    <NatureWrapper ref={natureWrapperRef}>
      <NatureCol1 ref={natureCol1Ref}>
        <Img
          fluid={
            data.allImageSharp.edges.find((element: any) => {
              return element.node.fluid.src.split("/").pop() === props.imgBig;
            }).node.fluid
          }
          alt={"Image 1"}
          loading={"eager"}
        />
      </NatureCol1>
      <NatureCol2 ref={natureCol2Ref}>
        <Img
          fluid={
            data.allImageSharp.edges.find((element: any) => {
              return element.node.fluid.src.split("/").pop() === props.imgSmall;
            }).node.fluid
          }
          alt={"Image 2"}
          loading={"eager"}
        />
      </NatureCol2>
    </NatureWrapper>
  );
};

export default Nature;
