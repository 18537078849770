import styled from "styled-components";
import { mediaMin, mediaMax } from "../../../helpers/MediaQueries";

export const BackYardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 10% 72px 0;
  ${mediaMax.tabletLandscape`
    margin: 0 6.25% ;
    flex-direction: column-reverse;
  `}
`;

export const BackYardTextWrapper = styled.div`
  width: 48%;
  display: flex;
  align-items: center;
  position: relative;
  ${mediaMax.tabletLandscape`
    width: 100%;
  `}
`;

export const BackYardImage = styled.img`
  position: absolute;
  bottom: 0;
  left: 10%;
  ${mediaMin.desktop`
    left: 15%;
  `}
  ${mediaMax.tabletLandscape`
    display: none;
  `}
`;

export const BackYardText = styled.p`
  font: normal normal 100 26px/32px Optima;
  max-width: 300px;
  color: #535d53;
  font-style: italic;
  margin-left: 32%;
  ${mediaMax.tabletLandscape`
    max-width: 500px;
    width: 100%;
    margin: 48px auto;
  `}
`;

export const BackYardImg = styled.div`
  width: 52%;
  ${mediaMax.tabletLandscape`
    width: 100%;
  `}
`;
