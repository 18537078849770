import React, { FC, useEffect, useRef } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { IContentImageTextProps } from "../../../interfaces/content-image-text-props";
import {
  ContentImageTextCol1,
  ContentImageTextCol2,
  ContentImageTextWrapper,
  ContentImageTextRightContainer,
} from "./index.styled";

const ContentImageText: FC<IContentImageTextProps> = ({ ...props }) => {
  const contentImageTextWrapperRef = useRef<HTMLDivElement>(null);
  const contentImageTextCol1Ref = useRef<HTMLDivElement>(null);
  const contentImageTextCol2Ref = useRef<HTMLDivElement>(null);
  const contentImageTextHeaderRef = useRef<HTMLHeadingElement>(null);
  const contentImageTextTextRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: contentImageTextWrapperRef?.current || "",
      toggleActions: "play none none reverse",
      start: "top+=20% bottom",
      animation: gsap
        .timeline()
        .fromTo(
          contentImageTextCol1Ref?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          "+=.1"
        )
        .fromTo(
          contentImageTextCol2Ref?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          "-=.2"
        )
        .fromTo(
          contentImageTextHeaderRef?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          "-=.2"
        )
        .fromTo(
          contentImageTextTextRef?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          "-=.2"
        ),
    });
  }, []);

  const data = useStaticQuery(graphql`
    query {
      allImageSharp {
        edges {
          node {
            fluid(maxWidth: 2560) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  `);

  return (
    <ContentImageTextWrapper
      ref={contentImageTextWrapperRef}
      reversed={props.reversed}
    >
      <ContentImageTextCol1
        ref={contentImageTextCol1Ref}
        imgMulti2={props.imgMulti2}
      >
        <Img
          fluid={
            data.allImageSharp.edges.find((element: any) => {
              return (
                element.node.fluid.src.split("/").pop() === props.imgMulti1
              );
            }).node.fluid
          }
          alt={props.title}
          loading={"eager"}
        />

        {props.imgMulti2 && (
          <Img
            fluid={
              data.allImageSharp.edges.find((element: any) => {
                return (
                  element.node.fluid.src.split("/").pop() === props.imgMulti2
                );
              }).node.fluid
            }
            alt={props.title}
            loading={"eager"}
          />
        )}
      </ContentImageTextCol1>
      {props.title && (
        <ContentImageTextCol2
          ref={contentImageTextCol2Ref}
          reversed={props.reversed}
        >
          <ContentImageTextRightContainer>
            <h2 className={"body-title"} ref={contentImageTextHeaderRef}>
              {props.title}
            </h2>
            <p className={"body-copy"} ref={contentImageTextTextRef}>
              {props.description}
            </p>
          </ContentImageTextRightContainer>
        </ContentImageTextCol2>
      )}
    </ContentImageTextWrapper>
  );
};

export default ContentImageText;
