import React, { FC, useEffect, useRef } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import iconPalm from "../../../images/icon-palm-large.svg";
import { IBackYardProps } from "../../../interfaces/back-yard-props";
import {
  BackYardWrapper,
  BackYardTextWrapper,
  BackYardImage,
  BackYardText,
  BackYardImg,
} from "./index.styled";

const BackYard: FC<IBackYardProps> = ({ ...props }) => {
  const backYardWrapperRef = useRef<HTMLDivElement>(null);
  const backYardTextRef = useRef<HTMLParagraphElement>(null);
  const backYardImgRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: backYardWrapperRef?.current || "",
      toggleActions: "play none none reverse",
      start: "top+=20% bottom",
      animation: gsap
        .timeline()
        .fromTo(
          backYardTextRef?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          "+=.1"
        )
        .fromTo(
          backYardImgRef?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          "+=.1"
        ),
    });
  });

  const data = useStaticQuery(graphql`
    query {
      allImageSharp {
        edges {
          node {
            fluid(maxWidth: 2560) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  `);

  return (
    <BackYardWrapper ref={backYardWrapperRef}>
      <BackYardTextWrapper>
        <BackYardImage src={iconPalm} />
        <BackYardText ref={backYardTextRef}>{props.text}</BackYardText>
      </BackYardTextWrapper>
      <BackYardImg ref={backYardImgRef}>
        <Img
          fluid={
            data.allImageSharp.edges.find((element: any) => {
              return element.node.fluid.src.split("/").pop() === props.img;
            }).node.fluid
          }
          alt={"Parkhouse image"}
          loading={"eager"}
        />
      </BackYardImg>
    </BackYardWrapper>
  );
};

export default BackYard;
