import styled from "styled-components";
import { mediaMax } from "../../../helpers/MediaQueries";

export const NatureWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 10% 72px;
  ${mediaMax.tabletLandscape`
    margin: 0 6.25% 48px;
  `}
  ${mediaMax.phoneXL`
    flex-wrap: wrap;
  `}
`;

export const NatureCol1 = styled.div`
  width: 49%;
  .gatsby-image-wrapper {
    width: 100%;
  }
  ${mediaMax.tabletLandscape`
    width: 61.7%;
  `};
  ${mediaMax.phoneXL`
    width: 100%;
  `};
`;

export const NatureCol2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  width: 47%;
  .gatsby-image-wrapper {
    width: 66%;
  }
  ${mediaMax.tabletLandscape`
    width: 31.3%;
    .gatsby-image-wrapper {
      width: 100%;
    }
  `};
  ${mediaMax.phoneXL`
    display: none;
  `};
`;
