import React, { useState, useRef } from "react";
import ReactMapGL, {
  Marker,
  Popup,
  FlyToInterpolator,
  NavigationControl,
} from "react-map-gl";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import useWindowSize from "../../../helpers/UseWindowSize";
import PHIcon from "../../../images/map-PH-icon.svg";
import mapParks from "./map-items/map-parks.json";
import mapCulinaries from "./map-items/map-culinaries.json";
import mapCultures from "./map-items/map-cultures.json";
import mapShops from "./map-items/map-shops.json";
import {
  MapWrapper,
  MapHeading,
  MapItemsCategories,
  NavItemsList,
  MapNav,
  MapMarker,
  PHMarker,
  PopupMapItemName,
  PopupMapItemAddress,
  MapContainer,
} from "./index.styled";

mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const navControlStyle = {
  right: 10,
  top: 10,
};

const Map = () => {
  const size = useWindowSize();
  const categoriesList = useRef<HTMLUListElement>(null);
  const [zoom] = useState(11.6);
  const [lat] = useState(33.63928);
  const [lng] = useState(-117.86009);

  const [settings] = useState({
    scrollZoom: false,
    keyboard: false,
  });

  const [viewport, setViewport] = useState({
    latitude: lat,
    longitude: lng,
    zoom,
    width: "100%",
    height: "100%",
  });
  const [selectedMapItem, setSelectedMapItem] = useState<any>(null);
  const [selectedMapCategory, setSelectedMapCategory] = useState(mapCulinaries);

  const setCategoryActive = (cat: any, e: any) => {
    const catItems = categoriesList?.current?.children;
    if (catItems) for (const item of catItems) item.classList.remove("active");
    e.target.classList.add("active");
    setSelectedMapCategory(cat);
  };
  const goToMarker = (item: any) => {
    setViewport({
      latitude: item.geometry.coordinates[1],
      longitude: item.geometry.coordinates[0],
      width: "100%",
      height: "100%",
      zoom: 13.5,
    });
    setSelectedMapItem(item);
  };
  return (
    <MapWrapper>
      <MapNav>
        <MapHeading>NEIGHBORHOOD MAP</MapHeading>

        <MapItemsCategories>
          <ul ref={categoriesList}>
            <li
              className={"active"}
              onClick={(e) => {
                setCategoryActive(mapCulinaries, e);
                setSelectedMapItem(null);
              }}
            >
              CULINARY
            </li>
            <li
              onClick={(e) => {
                setCategoryActive(mapCultures, e);
                setSelectedMapItem(null);
              }}
            >
              CULTURE
            </li>
            <li
              onClick={(e) => {
                setCategoryActive(mapParks, e);
                setSelectedMapItem(null);
              }}
            >
              PARKS & FITNESS
            </li>
            <li
              onClick={(e) => {
                setCategoryActive(mapShops, e);
                setSelectedMapItem(null);
              }}
            >
              SHOPPING
            </li>
          </ul>
        </MapItemsCategories>
        {size.width > 576 && (
          <NavItemsList>
            {selectedMapCategory.name !== "shopping" &&
              selectedMapCategory.features.map((item) => {
                return (
                  <li onClick={() => goToMarker(item)} key={item.properties.ID}>
                    {item.properties.NAME}
                  </li>
                );
              })}

            {selectedMapCategory.name === "shopping" && (
              <>
                <p>Culinary Markets</p>
                {selectedMapCategory.features.map((item) => {
                  return (
                    item.properties.CENTER === "Culinary Markets" && (
                      <li
                        onClick={() => goToMarker(item)}
                        key={item.properties.ID}
                      >
                        {item.properties.NAME}
                      </li>
                    )
                  );
                })}

                <p>Lido Marina Village</p>
                {selectedMapCategory.features.map((item) => {
                  return (
                    item.properties.CENTER === "Lido Marina Village" && (
                      <li
                        onClick={() => goToMarker(item)}
                        key={item.properties.ID}
                      >
                        {item.properties.NAME}
                      </li>
                    )
                  );
                })}

                <p>Fashion Island</p>
                {selectedMapCategory.features.map((item) => {
                  return (
                    item.properties.CENTER === "Fashion Island" && (
                      <li
                        onClick={() => goToMarker(item)}
                        key={item.properties.ID}
                      >
                        {item.properties.NAME}
                      </li>
                    )
                  );
                })}

                <p>South Coast Plaza</p>
                {selectedMapCategory.features.map((item) => {
                  return (
                    item.properties.CENTER === "South Coast Plaza" && (
                      <li
                        onClick={() => goToMarker(item)}
                        key={item.properties.ID}
                      >
                        {item.properties.NAME}
                      </li>
                    )
                  );
                })}

                <p>Others</p>
                {selectedMapCategory.features.map((item) => {
                  return (
                    item.properties.CENTER === "Others" && (
                      <li
                        onClick={() => goToMarker(item)}
                        key={item.properties.ID}
                      >
                        {item.properties.NAME}
                      </li>
                    )
                  );
                })}
              </>
            )}
          </NavItemsList>
        )}
      </MapNav>

      <MapContainer>
        <ReactMapGL
          {...viewport}
          {...settings}
          mapStyle={"mapbox://styles/dboxcartifact/cko3jgd3g00px19ufv83l9vnt"}
          mapboxApiAccessToken={
            "pk.eyJ1IjoiZGJveGNhcnRpZmFjdCIsImEiOiJja28zamNyMzUwYW5vMm9xd2JtMWYxeWQzIn0.CCNGewPeaiydOFITf8Rvyg"
          }
          transitionInterpolator={new FlyToInterpolator()}
          transitionDuration={300}
          onViewportChange={(nextViewport: any) => {
            setViewport(nextViewport);
          }}
        >
          <NavigationControl style={navControlStyle} />

          <Marker longitude={-117.860839} latitude={33.661873}>
            <PHMarker src={PHIcon} alt="Parkhouse Icon" />
            <MapMarker />
          </Marker>

          {selectedMapCategory.features.map((item) => (
            <Marker
              key={item.properties.ID}
              longitude={item.geometry.coordinates[0]}
              latitude={item.geometry.coordinates[1]}
            >
              <MapMarker
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedMapItem(item);
                  setViewport({
                    latitude: item.geometry.coordinates[1],
                    longitude: item.geometry.coordinates[0],
                    zoom: viewport.zoom,
                    width: "100%",
                    height: "100%",
                  });
                }}
              >
                <span />
              </MapMarker>
            </Marker>
          ))}

          {selectedMapItem ? (
            <Popup
              className={"popup-content"}
              longitude={selectedMapItem?.geometry.coordinates[0]}
              latitude={selectedMapItem?.geometry.coordinates[1]}
              onClose={() => setSelectedMapItem(null)}
            >
              <PopupMapItemName>
                {selectedMapItem?.properties.NAME}
              </PopupMapItemName>
              <PopupMapItemAddress>
                <p>{selectedMapItem?.properties.ADDRESSFORCALLOUT}</p>
                <p>{selectedMapItem?.properties.ADDRESSFORCALLOUT2}</p>
              </PopupMapItemAddress>
            </Popup>
          ) : null}
        </ReactMapGL>
      </MapContainer>
      {size.width < 576 && (
        <NavItemsList>
          {selectedMapCategory.name !== "shopping" &&
            selectedMapCategory.features.map((item) => {
              return (
                <li onClick={() => goToMarker(item)} key={item.properties.ID}>
                  {item.properties.NAME}
                </li>
              );
            })}
          {selectedMapCategory.name === "shopping" && (
            <>
              <p>Culinary Markets</p>
              {selectedMapCategory.features.map((item) => {
                return (
                  item.properties.CENTER === "Culinary Markets" && (
                    <li
                      onClick={() => goToMarker(item)}
                      key={item.properties.ID}
                    >
                      {item.properties.NAME}
                    </li>
                  )
                );
              })}

              <p>Lido Marina Village</p>
              {selectedMapCategory.features.map((item) => {
                return (
                  item.properties.CENTER === "Lido Marina Village" && (
                    <li
                      onClick={() => goToMarker(item)}
                      key={item.properties.ID}
                    >
                      {item.properties.NAME}
                    </li>
                  )
                );
              })}

              <p>Fashion Island</p>
              {selectedMapCategory.features.map((item) => {
                return (
                  item.properties.CENTER === "Fashion Island" && (
                    <li
                      onClick={() => goToMarker(item)}
                      key={item.properties.ID}
                    >
                      {item.properties.NAME}
                    </li>
                  )
                );
              })}

              <p>South Coast Plaza</p>
              {selectedMapCategory.features.map((item) => {
                return (
                  item.properties.CENTER === "South Coast Plaza" && (
                    <li
                      onClick={() => goToMarker(item)}
                      key={item.properties.ID}
                    >
                      {item.properties.NAME}
                    </li>
                  )
                );
              })}

              <p>Others</p>
              {selectedMapCategory.features.map((item) => {
                return (
                  item.properties.CENTER === "Others" && (
                    <li
                      onClick={() => goToMarker(item)}
                      key={item.properties.ID}
                    >
                      {item.properties.NAME}
                    </li>
                  )
                );
              })}
            </>
          )}
        </NavItemsList>
      )}
    </MapWrapper>
  );
};
export default Map;
