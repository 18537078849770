import React, { FC, useEffect, useRef } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import { IContentType1Props } from "../../../interfaces/content-type1-props";
import {
  ContentTypeCol1,
  ContentTypeCol2,
  ContentType1Wrapper,
  ContentType1RightContainer,
} from "./index.styled";
import { FullscreenImageLabel } from "../../fullscreen-image/index.styled";

const Access: FC<IContentType1Props> = ({ ...props }) => {
  const contentType1WrapperRef = useRef<HTMLDivElement>(null);
  const contentTypeCol1Ref = useRef<HTMLDivElement>(null);
  const contentType1Col2ImgRef = useRef<HTMLDivElement>(null);
  const contentType1HeaderRef = useRef<HTMLHeadingElement>(null);
  const contentType1TextImgRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: contentType1WrapperRef?.current || "",
      toggleActions: "play none none reverse",
      start: "top+=30% bottom",
      animation: gsap
        .timeline()
        .fromTo(
          contentTypeCol1Ref?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          "0.1"
        )
        .fromTo(
          contentType1HeaderRef?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          "0.2"
        )
        .fromTo(
          contentType1TextImgRef?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          "0.3"
        )
        .fromTo(
          contentType1Col2ImgRef?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          "0.4"
        ),
    });
  }, []);

  const data = useStaticQuery(graphql`
    query {
      allImageSharp {
        edges {
          node {
            fluid(maxWidth: 2560) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  `);

  return (
    <ContentType1Wrapper ref={contentType1WrapperRef}>
      <ContentTypeCol1 ref={contentTypeCol1Ref}>
        <Img
          fluid={
            data.allImageSharp.edges.find((element: any) => {
              return element.node.fluid.src.split("/").pop() === props.imgBig;
            }).node.fluid
          }
          alt="residence image"
          loading={"eager"}
        />
        <FullscreenImageLabel
          className={"image-label"}
          labelWidth={props.labelWidth}
        >
          {props.label}
        </FullscreenImageLabel>
      </ContentTypeCol1>
      <ContentTypeCol2 ref={contentType1Col2ImgRef}>
        <Img
          fluid={
            data.allImageSharp.edges.find((element: any) => {
              return element.node.fluid.src.split("/").pop() === props.imgSmall;
            }).node.fluid
          }
          alt={"residence image 2"}
          loading={"eager"}
        />
        <ContentType1RightContainer>
          <h2 className={"body-title"} ref={contentType1HeaderRef}>
            {props.title}
          </h2>
          <p className={"body-copy"} ref={contentType1TextImgRef}>
            {props.description}
          </p>
        </ContentType1RightContainer>
      </ContentTypeCol2>
    </ContentType1Wrapper>
  );
};

export default Access;
