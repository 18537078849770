import React, { useContext, useEffect } from "react";
import useWindowSize from "../helpers/UseWindowSize";
import IntroImage from "../components/intro-image";
import IntroText from "../components/intro-text";
import FullscreenImage from "../components/fullscreen-image";
import Nature from "../components/neighborhood/nature";
import Access from "../components/neighborhood/content-type1";
import Center from "../components/neighborhood/center";
import BackYard from "../components/neighborhood/back-yard";
import WaterGrill from "../components/neighborhood/water-grill";
import Map from "../components/neighborhood/map";
import PageTransitionSection from "../components/page-transition-section";
import ContentImageText from "../components/neighborhood/content-img-text";
import FirstLoadContext from "../context";
import { Helmet } from "react-helmet";
import FooterSeo from "../components/footer-seo";

const introText = {
  title: "NEIGHBORHOOD",
  text: "Known for high-end shopping destinations, Michelin-starred restaurants, world-class cultural institutions and an abundance of outdoor pursuits, Newport Beach has long been Southern California’s most desirable address.",
};
const natureText = {
  title: "NATURE IS HERE TO NURTURE",
  text: "A local’s favorite, the Back Bay is crisscrossed with numerous biking, hiking, and walking trails for all fitness levels. The bay itself welcomes paddle boarding, kayaking, canoeing, picnicking, or horseback riding along its saline shores. Consider it an extension of your backyard.",
};
const natureHighlightedText = {
  title: "An extension of your backyard, gifted by Mother Nature",
};
const accessText = {
  title: "THE EASIEST OF ACCESS",
  text: "A 5-minute drive from Parkhouse\u00A0Residences is all it takes to arrive at John Wayne Airport, providing ease of access to the gates or to the tarmac of the private-jet terminal. Along with John Wayne Airport, every leisure and diversion in Newport Beach is within 20 minutes of Parkhouse Residences. Tackle the Tom Fazio-designed courses at Pelican Hill Golf Club. Stroll Balboa Island’s historic boardwalk with its charming, family attractions. Spend the day on your boat out of Newport Harbor, or just cruise along Pacific Coast Highway with the top down.",
};

const Neighborhood = () => {
  const { setFirstLoad } = useContext(FirstLoadContext);
  useEffect(() => {
    setFirstLoad(false);
  }, [setFirstLoad]);

  const size = useWindowSize();
  return (
    <>
      <Helmet>
        <title>The neighborhood | Parkhouse Residences Newport Beach</title>
        <meta name="description" content="Parkhouse Residences is conveniently located in Newport Beach which has long been known for its high-end shopping destinations, Michelin-star restaurants, world-class cultural institutions and abundance of outdoor pursuits." />
      </Helmet>
      <IntroImage imageSrc={"neighborhood-hero.jpg"} />
      <IntroText title={introText.title} text={introText.text} />
      <Nature
        imgBig={"neighborhood-1.png"}
        imgSmall={
          size.width < 1025 ? "neighborhood-3.png" : "neighborhood-2.png"
        }
      />
      <ContentImageText
        title={natureText.title}
        description={natureText.text}
        imgMulti1={
          size.width < 1025 ? "neighborhood-2.png" : "neighborhood-3.png"
        }
        imgMulti2={size.width < 576 ? "neighborhood-3.png" : ""}
      />
      <BackYard text={natureHighlightedText.title} img={"neighborhood-4.png"} />
      <FullscreenImage
        label={"SEGERSTROM CENTER FOR THE ARTS"}
        imageSrc={"neighborhood-5.png"}
        withPadding={"100%"}
      />
      <Center />
      <FullscreenImage
        label={"SHERMAN LIBRARY & GARDEN"}
        imageSrc={"neighborhood-7.png"}
        withPadding={size.width < 1025 ? "87.5%" : "80%"}
        labelWidth={size.width < 1025 ? "87.5%" : "80%"}
      />
      <ContentImageText
        imgMulti1={"neighborhood-8.png"}
        imgMulti2={"neighborhood-9.png"}
      />
      <WaterGrill
        imgLeft={"neighborhood-10.png"}
        imgRight={"neighborhood-11.png"}
        label={"WATER GRILL"}
      />
      <FullscreenImage imageSrc={"neighborhood-12.png"} withPadding={"100%"} />
      <Access
        title={accessText.title}
        description={accessText.text}
        imgBig={
          size.width < 1025 ? "neighborhood-14.png" : "neighborhood-13.png"
        }
        imgSmall={
          size.width < 1025 ? "neighborhood-13.png" : "neighborhood-14.png"
        }
      />
      <FullscreenImage
        imageSrc={"neighborhood-15.png"}
        withPadding={size.width < 1025 ? "87.5%" : "80%"}
      />
      <Map />
      <PageTransitionSection text={"Back to Residences"} linkTo={""} />
      <FooterSeo />
    </>
  );
};

export default Neighborhood;
